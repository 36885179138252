




































































































































































































































































































































































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import Validation from "@/components/Validation.vue";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBranch } from "@/interfaces/IBranch";
import { COST_BEARER_TYPES } from "@/constants/CostBearerTypeId";
import { IInstructor } from "@/interfaces/IInstructor";
import { ITuv } from "@/interfaces/ITuv";
import { ICountry } from "@/interfaces/ICountry";
import FscCard from "@/components/Card/FscCard.vue";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import { ITheoryCourse } from "@/interfaces/ITheoryCourse";
import { ILicenseClass } from "@/interfaces/ILicenseClass";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import moment from "moment";
import Datepicker from "@/components/Datepicker.vue";
import { IPriceList } from "@/interfaces/IPriceList";
import ProfilePhoto from "@/views/Student/ProfilePhoto.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import EducationItem from "@/views/Student/EducationItem.vue";
import MandateConfirmationModal from "@/views/Student/MandateConfirmationModal.vue";
import MandateRevokeConfirmationModal from "@/views/Student/MandateRevokeConfirmationModal.vue";
import { v4 as uuidv4 } from "uuid";
import MailButton from "@/components/Button/MailButton.vue";
import SendPasswordModal from "@/views/Student/SendPasswordModal.vue";
import { EDIT } from "@/constants/FormType";
import PlzInput from "@/components/Field/PlzInput.vue";
import KeyNumberRequestMixin from "@/mixins/Request/KeyNumberRequestMixin";
import GrantingTypeRequestMixin from "@/mixins/Request/GrantingTypeRequestMixin";

@Component({
  components: {
    PlzInput,
    SendPasswordModal,
    MailButton,
    MandateRevokeConfirmationModal,
    MandateConfirmationModal,
    EducationItem,
    ProfilePhoto,
    Datepicker,
    FscCardHeader,
    FscCard,
    SaveButton,
    AbortButton,
    FormCard,
    Validation,
  },
})
export default class StudentEducationTab extends mixins(StudentMixin, KeyNumberRequestMixin, GrantingTypeRequestMixin) {
  public name = "StudentEducationTab";

  @Prop()
  public studentData!: any;

  @Prop({ default: () => [] })
  public educationData!: any;

  @Prop({ default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ default: () => [] })
  public tuvServices!: Array<ITuv>;

  @Prop({ default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ default: () => [] })
  public countries!: Array<ICountry>;

  @Prop({ default: () => [] })
  public theoryCourses!: Array<ITheoryCourse>;

  @Prop({ default: () => [] })
  public licenseClasses!: Array<ILicenseClass>;

  @Prop({ default: () => [] })
  public paymentWorkflows!: Array<IBasicNamedDTO>;

  @Prop({ default: () => [] })
  public priceList!: Array<IPriceList>;

  @Prop({ default: () => [] })
  public testingOrganization!: any;

  @Prop()
  public v!: any;

  @Prop()
  public costBearers!: any;

  @Prop({ default: () => 0 })
  public activeTabIndex!: number;

  @Prop()
  public type!: any;

  @Prop()
  public checkListTemplates!: any;

  @Prop()
  public activeChecklistTemplate!: any;

  @Prop()
  public keyNumbers!: any;

  @Prop()
  public grantingTypes!: any;

  protected mandateConfirmationModalId = `mandate-confirmation-modal-id-${this.uuid}`;
  protected mandateRevokeConfirmationModalId = `mandate-revoke-confirmation-modal-id-${this.uuid}`;

  protected mandateRevokeEducation: IStudentEducation | null = null;
  protected sendPasswordModalId = "send-password-modal-id";

  protected educationForEdit = "";
  protected educationForEditOptions = ["B", "B197", "B78"];
  public educationEditDTO: any = {};

  public livePayDeselected = false;
  public previousWorkflow: any = "";
  public moment: any = moment;

  public get costBearerOption(): Array<any> {
    return this.costBearers;
  }

  @Inject("mandateRevoke")
  public mandateRevoke!: (mandateId: number) => void;

  @Inject({ from: "editEducation", default: () => null })
  protected editEducation: any;

  public get branchesOptions(): Array<any> {
    return this.branches.map((branch: IBranch) => {
      return {
        name: `${branch.postalCode} - ${branch.location}`,
        id: branch.id,
        priceList: branch.priceList,
      };
    });
  }

  public get studentAge() {
    if (!this.studentData.birthDate) return "";
    return moment(new Date()).diff(moment(this.studentData.birthDate), "years");
  }

  public get checkListTemplatesOption(): Array<any> {
    return this.checkListTemplates;
  }

  public get costBearerTypeIdOption(): Array<any> {
    return COST_BEARER_TYPES;
  }

  public get titleOption(): Array<any> {
    return ["Herr", "Frau"];
  }

  public get theoryCourseOption(): Array<ITheoryCourse> {
    return this.theoryCourses;
  }

  public get paymentWorkflowOptions(): Array<any> {
    return this.paymentWorkflows;
  }

  public get priceListOption(): Array<any> {
    return this.priceList;
  }

  public get nationalityOption(): Array<any> {
    return this.countries;
  }

  public get licenseClassOption(): Array<any> {
    return this.licenseClasses.map((licenseClass: any) => {
      return {
        licenseClass: licenseClass,
        keyNumber: null,
      };
    });
  }

  public get testingOrganizationOptions(): Array<any> {
    return this.testingOrganization.map((organization: any) => {
      return {
        id: organization.id,
        name: organization.name,
      };
    });
  }

  public get licenseClassOptionsExisting(): Array<any> {
    return this.licenseClasses;
  }

  public get instructorOption(): Array<any> {
    return this.instructors;
  }

  private clickedAdd = false;

  public addEducation(): void {
    this.clickedAdd = true;
    this.$emit("add-education");
  }

  public getTabTitleByEducation(education: IStudentEducation): string {
    if (education?.licenseClassWithKeyNumber && education.licenseClassWithKeyNumber?.keyNumber) {
      return `${education.licenseClassWithKeyNumber.licenseClass}${education.licenseClassWithKeyNumber.keyNumber}`;
    } else if (education.licenseClassWithKeyNumber?.licenseClass) {
      return `${education.licenseClassWithKeyNumber.licenseClass}`;
    }
    return "-";
  }

  public onTabChanged(tabIndex: number): void {
    this.$emit("tab-index", tabIndex);
  }

  public onUploadFile(file: File): void {
    this.$emit("file", file);
  }

  protected get profilePhoneUrl(): string {
    const id = this.$route.params.id;
    if (!id) return "";
    const URL = "students";
    return `${URL}/avatar?studentId=${id}`;
  }

  protected onSelectBranch(selectedOption: IBranch): void {
    this.$emit("on-select-branch", selectedOption);
  }

  protected onClickMandate(): void {
    this.$bvModal.show(this.mandateConfirmationModalId);
  }

  protected okSetUpMandate(): void {
    this.$router.push({
      name: "Mandate",
      params: {
        setUp: String(true),
        education: this.educationData[this.activeTabIndex],
      },
    });
  }

  protected okRevokeMandate(): void {
    if (this.mandateRevokeEducation && this.mandateRevokeEducation.id) {
      this.mandateRevoke(this.mandateRevokeEducation.id);
      this.mandateRevokeEducation = null;
    }
  }

  protected onClickMandateRevoke(education: IStudentEducation): void {
    this.mandateRevokeEducation = education;
    this.$bvModal.show(this.mandateRevokeConfirmationModalId);
  }

  protected get uuid(): any {
    return uuidv4();
  }

  public sendPasswordAgain(): void {
    if (!this.studentData.email) return;
    this.$bvModal.show(this.sendPasswordModalId);
  }

  public get typeEdit(): boolean {
    return this.type === EDIT;
  }

  public onEditEducationClick(education: any) {
    this.educationEditDTO = education;
    this.educationForEdit = education.mainLicenseClass;
    this.$bvModal.show("education-modal");
  }

  public onEditEducationConfirm() {
    this.educationEditDTO.mainLicenseClass = this.educationForEdit;
    this.$bvModal.hide("education-modal");
    this.editEducation(this.educationEditDTO);
  }

  public onLivePayModalShow(defaultWorkflow: any) {
    this.previousWorkflow = defaultWorkflow;
    this.$bvModal.show("live-pay-modal-id");
  }

  public livePaySelect() {
    this.$bvModal.hide("live-pay-modal-id");
    this.livePayDeselected = false;
  }

  public livePayDeselect() {
    this.$bvModal.hide("live-pay-modal-id");
    this.livePayDeselected = true;
  }

  public onLivePaySelection() {
    this.livePayDeselected = false;
  }

  public get educationDataComputed() {
    if (this.$route.query.onboarding == "true") {
      return this.educationData?.filter((education: any) => education.licenseClassWithKeyNumber?.licenseClass !== null);
    }
    return this.educationData;
  }

  public async onSelectEducation(licenseClass: string, index: number, fetchKeyNumbers = true) {
    if (!licenseClass) return;
    if (fetchKeyNumbers) {
      await this.findKeysByLicenseClass(licenseClass);
    }
    await this.fetchGrantingTypesByLicenseClass(licenseClass);

    if (fetchKeyNumbers) {
      this.$set(this.keyNumbers, index, this.keysByLicenseClass);
    }
    this.$set(this.grantingTypes, index, this.grantingTypesOptions);
  }

  public changedTabs(currentTabs: any, previousTabs: any) {
    if (currentTabs.length == previousTabs.length && this.clickedAdd) {
      this.$emit("update:activeTabIndex", currentTabs.length - 1);
      this.clickedAdd = false;
    }
  }
}
